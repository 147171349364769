<template>
  <section feature-title-list v-editable="blok" :data-style="blok.style || 'default'" :style="[styleBackground]">
    <div class="container">
      <div class="row">
        <div class="section-content">
          <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
          <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
        </div>
        <div class="section-list" v-if="blok.list" v-html="list"></div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/feature-title-list.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });

const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));
const list = computed(() => renderRichText(props?.blok?.list || {}));

const styleBackground = computed(() => {
  if (props.blok.background?.value) {
    if (props.blok.background?.value === "#E9E1F7") {
      return { background: "linear-gradient(30deg, rgba(233, 225, 247, 0.78) -10.52%, rgba(247, 248, 254, 0.68) 81.92%)" };
    }
    return { backgroundColor: props.blok.background?.value };
  }

  return { backgroundColor: "#fff" };
});
</script>
